<template>
  <div class="content-body">
    <div class="row mt-2 mg-sm-0 no-print">
      <div class="col-7">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">Contact Detail</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Manage Contact Details
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">
              Manage Personal Contact Detail
            </h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
      <div class="col-5 tx-right">
        <button
          type="button"
          v-if="checkSinglePermission('create_contact_detail')"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="
            $store.dispatch('modalWithMode', {
              id: 'newContact',
              mode: 'create',
            })
          "
        >
          New Contact
        </button>
      </div>
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head no-print pt-0">
      <div class="row">
        <div class="filter-input col-9 d-block mr-0 pr-0">
          <select name="size" v-model="params.size" @change="searchFilter" class="mr-2 mg-t-5">
            <option value="">All</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div class="col-3 ml-0 pl-0 mg-t-5">
          <input type="text" class="float-right" placeholder="Search..." v-model="params.searched" @keyup="searchFilter" />
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12 overflow-x-auto">
        <table class="table table2 table-hover" id="exampleTable">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N.</th>
              <th class="wd-20p">Name</th>
              <th class="wd-20p">Organization</th>
              <th class="wd-10p">Designation</th>
              <th class="wd-15p">Email</th>
              <th class="wd-20p text-right ">Phone No</th>
              <th class="wd-10p text-right table-end-item no-print" v-if="checkIfAnyPermission(['edit_contact_detail','delete_contact_detail'])">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && contactDatas.length >0">
            <tr  v-for="(contact, index) in contactDatas" :key="index">
              <th scope="row">{{ pageSerialNo+index }}.</th>
              <td>{{ contact.name }}</td>
              <td><span v-if="contact.company">{{ contact.company_name.name }}</span><span v-if="contact.company == 0">Others</span></td>
              <td>{{contact.designation}}</td>
              <td>{{ contact.email }}</td>
              <td class="text-right ">{{ contact.phone_no }}</td>
              <td class="text-right table-end-item no-print" v-if="checkIfAnyPermission(['edit_contact_detail','delete_contact_detail'])">
                <!-- <a href="javascript:;" class="mr-3">
                  <i
                    class="fas fa-eye tx-success"
                    @click="view(contact.id)"
                  ></i>
                </a> -->
                <a href="javascript:;" v-if="checkSinglePermission('edit_contact_detail')" class="mr-3">
                  <i
                    class="fa fa-edit"
                    @click="edit('editContact', contact.id)"
                  ></i>
                </a>
                <a href="javascript:;" v-if="checkSinglePermission('delete_contact_detail')">
                  <i class="fa fa-trash" @click="drop(contact.id)"></i>
                </a>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!loading && contactDatas.length == 0">
              <tr>
              <td  colspan="11" style="text-align:center;">
                  No records found.
              </td>
              </tr>
          </tbody>
          <tbody v-else-if="loading">
              <tr>
              <td  colspan="11" style="text-align:center;">
                  Loading.....
              </td>
              </tr>
            </tbody>
              <tbody v-else-if="error">
                  <td colspan="11" class="text-center">{{error_message}}</td>
              </tbody>
        </table>
         <Paginate v-model="page" :pageCount="totalPageCount"
                :containerClass="'pagination'"
                :clickHandler="clickCallback"
                v-if="totalPageCount > 1"
                />
      </div>
    </div>
    <AddDetail @parent-event="getData()" />
  </div>
</template>
<script>
import AddDetail from "./create";
import Services from "./Services/Services";
import Paginate from 'vuejs-paginate';
import { mapGetters } from "vuex";
import _ from 'lodash';
export default {
  components: {
    AddDetail,Paginate
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
     ...mapGetters("contactDetails",["contactDatas"])
  },
  data(){
    return{
       params:{
          size:10,
          searched:'',

      },
      totalPageCount:0,
      pageSerialNo:1,
      loading:true,
      error:false,
      error_message:'',
      page : 1,
    }
  },
  mounted() {
    this.getData();
  },
 methods: {
   clickCallback: function(page) {
      if(page == 1){
          this.pageSerialNo = 1;
      }else{
          this.pageSerialNo = ((page-1) * this.params.size) + 1 ;
      }
      this.params.offset = (page-1) * this.params.size;
      this.getData();
    },
    getData(){
      this.loading = true;
      Services.getContactDetails(this.params).then(res=>{
          this.$store.commit("contactDetails/setContactDatas",res.data.data.data);
          this.loading = false;
          this.totalPageCount = res.data.data.total_no_of_pages;
      }).catch(err=>{
          this.error=true;
          this.loading=false;
           if(!Object.prototype.hasOwnProperty.call(err,"response.data.error_message")) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
              } else {
                this.error_message = "Error fetching data from the server.";
              }
          }

      })
    },
    searchFilter: _.debounce(function(){
      this.loading = true;
      this.page = 1;
      this.params.offset = 0;
      this.pageSerialNo = 1;
      Services.getContactDetails(this.params).then(res=>{
          this.$store.commit("contactDetails/setContactDatas",res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
          this.loading=false;
      }).catch(err=>{
          this.error = true;
          this.loading=false;
          let error = err.response.data.error_message;
           if(!Object.prototype.hasOwnProperty.call(err,"response.data.error_message")) {
            if (err.response.data.error_message == "Access Denied.") {
              this.error_message = "Access Denied !!!";
              } else {
                this.error_message = "Error fetching data from the server.";
              }
          }

          this.setNotification(error);
      });
    },1000),
    edit(modal, id) {
      this.$store.commit("setDataId", id);
      this.$store.dispatch("modalOpen", modal);
    },
    drop(id) {
       this.$swal({
       title: 'Do you really want to delete Contact Detail ?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Delete',
        cancelButtonText: 'No',
        showLoaderOnConfirm: true,
        preConfirm:() => {
          // delete lead
          return Services.deleteContactDetail(id).then((response) => {
            if(response.status == 200){
                let success = response.data.success_message;
                this.setNotification(success);
            }
            this.getData();
          }).catch(error => { 
            this.$swal.showValidationMessage(
                `Request failed: ${error.response.data.error_message}`
              );
              if(error.response.status == 500){
                let err = error.response.data.error_message;
                this.setNotification(err);
              }
          })
        }
      })
    },
    setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
  },
  watch: {
    eventMessage(value) {
      if (value.indexOf("deleted success") >= 0) {
        this.$store.commit("setRemoveList", this.dataId);
      }
    },
    destroyed() {
    this.$store.dispatch("destroyEvent");
    },
  },
  
};
</script>
<style scoped>
</style>