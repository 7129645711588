<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'newContact'||modalId == 'editContact'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
               <span v-if="modalId == 'newContact'">Add Contact Detail</span>
               <span v-else>Edit Contact Detail</span>
            </h5>
            <a
              class="close"
              title="Close"
              @click="$store.dispatch('modalClose')"
            >
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-6 pd-r-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group">
                      <label class="group-label">Basic Information</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Catagory *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select v-model="formData.catagory" required @change="getCompany" class="form-control">
                              <option value="" disabled>Select Catagory</option>
                              <option v-for="(method,index) in contactElements.account_types" :value="method.id" :key="index">{{method.title}}</option>
                              <option value="0">Others</option>
                            </select>
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Name *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <input
                              v-model="formData.name"
                              class="form-control"
                              placeholder="Person Name.."
                              required
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Address</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <input
                              v-model="formData.address"
                              class="form-control"
                              placeholder="Address.."
                            />
                          </div>
                        </div>
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Company *</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                             <select v-model="formData.company" required class="form-control">
                              <option value="" disabled>Select Company</option>
                              <option v-for="(method,index) in companies" :value="method.id" :key="index">{{method.name}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Designation</div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <input
                                    v-model="formData.designation"
                              class="form-control"
                              placeholder="Designation.."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 pd-l-7-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="group mb-3">
                      <label class="group-label">Contact Detail</label>
                      <div class="group-attribute-container">
                        <div class="row mb-2">
                          <div class="col-md-5 mr-0 pr-0">Phone No *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="tel"
                              step="any"
                              v-model="formData.phone_no"
                              class="form-control"
                              placeholder="98XXXXXXXX, 01-XXXXX"
                              required
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Email </div>
                          <div class="col-md-7 input-group ml-0 pl-0">
                            <input
                              type="email"
                              v-model="formData.email"
                              class="form-control"
                              placeholder="abc@domain.com"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="group">
                        <label class="group-label">Note</label>
                        <div class="group-attribute-container">
                            <div class="row">
                                <div class="col-md-12">
                                <textarea class="form-control" type="text" name="description"
                                            v-model="formData.note" style="height: 2.85rem;"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton">
                  {{(disableSubmitButton)?'Submitting...':'Submit'}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
import Services from './Services/Services';
  export default {
    data(){
      return{
        companies:[],
        disableSubmitButton:false,
        formData:{
          catagory:'',
          name:'',
          company: '',
          phone_no: '',
          address: '',
          designation: '',
          email: '',
          note: '',
        }
      }
    },
    computed: {
      ...mapGetters([
        "processing",
        "eventMessage",
        "dataLists",
        "dataLists1",
        "dataLists2",
        "dataId",
        "modalId",
        "modalMode",
      ]),
      ...mapGetters("contactDetails",["contactDatas","contactElements"])
    },
    mounted() {
    },
    methods: {
      clearData(){
          this.formData.catagory='';
          this.formData.name='';
          this.formData.company= '';
          this.formData.phone_no= '';
          this.formData.address= '';
          this.formData.designation= '';
          this.formData.email= '';
          this.formData.note= '';
          this.disableSubmitButton=false;
          this.companies=[];
      },
      submit() {
        this.disableSubmitButton = true;
        if (this.modalMode == "create") {
          Services.createNewContact(this.formData).then(res=>{
            this.$emit('parent-event');
            this.$store.dispatch("modalClose");
            this.setNotification(res.data.success_message);
          }).catch(err=>{
              if(err.response.status == 422){
                let error = Object.values(err.response.data.errors).flat();
                this.setNotification(error);
             }else{
                let error = err.response.data.error_message;
                this.setNotification(error);
             }
          }).finally(()=>{
            this.disableSubmitButton = false;
          });
        } else if (this.modalId == "editContact") {
          // this.$store.commit("setApiUrl", `api/contact-details/${this.formData.id}`);
          // this.$store.commit("updateData", {...this.formData});
          Services.updateContactDetail(this.formData,this.formData.id).then(res=>{
              this.$emit('parent-event');
              this.$store.dispatch("modalClose");
              this.setNotification(res.data.success_message);
          }).catch(err=>{
            if(err.response.status == 422){
                let error = Object.values(err.response.data.errors).flat();
                this.setNotification(error);
             }else{
                let error = err.response.data.error_message;
                this.setNotification(error);
             }
          }).finally(()=>{
            this.disableSubmitButton = false;
          });
        }
      },
      setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
      },
      getCompany(){
        let cat = this.formData.catagory;
        if(this.modalId == 'newContact'){
          this.formData.company = "";
        }
        if(cat != '' && cat != 0){
          console.log("inside");
            let catagory_type = this.contactElements.account_types.find(function(data){
                if(data.id == cat){
                  return data.slug;
                }
            });
            if(catagory_type.slug == 'customer'){
              this.companies = this.contactElements.customers;
            }
            else if(catagory_type.slug == 'supplier'){
              this.companies = this.contactElements.suppliers;
            }
            else if(catagory_type.slug == 'lead'){
              this.companies = this.contactElements.leads;
            }
            else{
              this.companies= [];
            }
        }else{
          
          this.companies = [{
              id:'0',
              name:'Others',
          }];
          this.formData.company = 0;
        }
        
      }
    },
    watch: {
      modalId(value) {
        this.clearData();
        Services.getContactElements().then(res=>{
            this.$store.commit("contactDetails/setContactElements",res.data.data);
            if(value == 'editContact'){
              let id = this.dataId;
              let details = this.contactDatas.find(function (data) {
                return data.id == id;
              });
              this.formData = {...details};
              this.getCompany();
            }
        }).catch(err=>{
          console.log(err);
        });
      },
    },
  }
</script>